import * as React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BsStars } from "react-icons/bs"
import { FaBrain } from "react-icons/fa"
import { GiFactory } from "react-icons/gi"
import moment from "moment"

const IdeaEnginePage = ({ data }) => {
  const brainstorms = data.brainstorm.nodes

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900 pt-1 pb-6  p-2">
      <Seo title="" />
      <Seo
        title={"Budmen Idea Engine"}
        description="Collaborative brainstorming tool for teams looking to generate and select the best ideas 💭"
        defer={false}
        image="https://cdn.sanity.io/images/rbhjt84j/production/3d78eca2bdbf8d20a9931a1d47ca0fb7632eeff7-1200x630.png"
        twitterImage="https://cdn.sanity.io/images/rbhjt84j/production/3d78eca2bdbf8d20a9931a1d47ca0fb7632eeff7-1200x630.png"
      />
      {/* Blog Posts */}
      <section className="container mx-auto bg-grid-white mt-8 rounded text-white border">
        <div className="grid grid-cols-3 px-8 py-12 bg-black/90 rounded">
          <div className="flex mb-3 flex-col justify-center items-center">
            <p className="tracking-widest md:text-xs text-[.625rem] md:inline-flex hidden items-center gap-3 uppercase">
              Brainstorming Tool <BsStars /> <FaBrain /> <GiFactory />
            </p>
            <h1 className="tracking-widest  md:text-8xl text-4xl font-bold">
              IDEA
            </h1>
          </div>
          <div className="w-full flex items-center col-span-2 justify-center md:mb-0 mb-3 bg-idea2 shadow-lg  rounded-full"></div>
          <div className="w-full flex items-center justify-center mb-0 bg-idea7 shadow-lg  rounded-full mr-4 md:mt-0 mt-3"></div>
          <h1 className="ml-4 tracking-widest flex justify-center col-span-2 items-center md:text-9xl text-4xl font-bold">
            ENGINE
          </h1>
          <p className="col-span-2 mb-0 mt-3 flex md:text-base text-xs items-center justify-center">
            Enter a mystical realm of idea-sharing and boundless creativity,
            where thoughts flow freely and the best ideas rise to the top. Use
            the Idea Engine to generate tons of ideas in no time, and quickly
            find the best ones to work on.
          </p>
          <div className="flex items-center justify-center">
            <img
              className="md:h-32 md:w-32"
              src="https://cdn.sanity.io/images/rbhjt84j/production/0ba0b47464ae262e9867a8e39e34516e5e412273-904x905.png"
            />
          </div>
        </div>
      </section>
      <section className="mt-8 container mx-auto lg:p-0 ">
        <h1 className="font-bold dark:text-white text-sm text-center tracking-widest mb-4">
          BRAINSTORMING SESSIONS
        </h1>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 w-full ">
          {brainstorms.map(
            (posts, index) =>
              posts.slug !== null &&
              posts.visible !== true && (
                <div
                  className="text-white h-full w-full bg-grid-white border hover:opacity-90 rounded"
                  key={index}
                >
                  <AniLink
                    to={"/idea-engine/" + posts.slug.current}
                    className="flex items-center gap-2 justify-center flex-col px-4 py-8 text-center  bg-black/90 rounded"
                    key={index}
                    paintDrip
                    duration={0.5}
                    hex={"#000cc5"}
                  >
                    <span className="text-[.475rem]">
                      {moment(posts.timestamp).format("MM/DD/YYYY")}
                    </span>
                    <h3 className="text-xs font-bold tracking-widest uppercase">
                      {posts.title}
                    </h3>
                    <p className="text-[.625rem]">{posts.description}</p>
                    <button className="text-[.625rem] tracking-widest uppercase font-bold rounded bg-idea9">
                      <span className="flex bg-black/20 p-1  px-2 py-1 rounded">
                        Join Session
                      </span>
                    </button>
                  </AniLink>
                </div>
              )
          )}
        </div>
      </section>
    </div>
  )
}

export default IdeaEnginePage

export const query = graphql`
  query IdeaEnginePageQuery {
    brainstorm: allSanityBrainstorm(
      sort: { fields: [title], order: ASC }
      filter: { _id: { glob: "!drafts*" }, status: { ne: false } }
    ) {
      nodes {
        _id
        id
        _type
        slug {
          current
        }
        status
        timestamp
        title
        description
        stormIdeas {
          timestamp
          status
          comment
          _key
          _type
          name
        }
        _createdAt
      }
    }
  }
`
